
export const holeData = [
    {
        center:{ x: 0, y:0, z: 280 },
        club:0,
        rings:[
            { radius: 60, score: 10, color:0xFFFFFF },
            { radius: 50, score: 20, color:0xFFFFFF },
            { radius: 45, score: 30, color:0xFFFFFF },
            { radius: 40, score: 40, color:0xFFFFFF },
            { radius: 35, score: 50, color:0xFFFFFF },
            { radius: 30, score: 60, color:0xFFFFFF },
            { radius: 25, score: 70, color:0xFFFFFF },
            { radius: 20, score: 80, color:0xFFFFFF },
            { radius: 15, score: 90, color:0xFFFFFF },
            { radius: 10, score: 100, color:0xFFFFFF }
        ],
        good_threshold: 60
    },
    {
        center:{ x: 0, y:0, z: 240 },
        club:1,
        rings:[
            { radius: 56, score: 10, color:0xFFFFFF },
            { radius: 46, score: 20, color:0xFFFFFF },
            { radius: 36, score: 30, color:0xFFFFFF },
            { radius: 32, score: 40, color:0xFFFFFF },
            { radius: 28, score: 50, color:0xFFFFFF },
            { radius: 24, score: 60, color:0xFFFFFF },
            { radius: 20, score: 70, color:0xFFFFFF },
            { radius: 16, score: 80, color:0xFFFFFF },
            { radius: 12, score: 90, color:0xFFFFFF },
            { radius: 8, score: 100, color:0xFFFFFF }
        ],
        good_threshold: 60
    },
    {
        center:{ x: 0, y:0, z: 210 },
        club:2,
        rings:[
            { radius: 56, score: 10, color:0xFFFFFF },
            { radius: 46, score: 20, color:0xFFFFFF },
            { radius: 36, score: 30, color:0xFFFFFF },
            { radius: 32, score: 40, color:0xFFFFFF },
            { radius: 28, score: 50, color:0xFFFFFF },
            { radius: 24, score: 60, color:0xFFFFFF },
            { radius: 20, score: 70, color:0xFFFFFF },
            { radius: 16, score: 80, color:0xFFFFFF },
            { radius: 12, score: 90, color:0xFFFFFF },
            { radius: 8, score: 100, color:0xFFFFFF }
        ],
        good_threshold: 60
    }
];

export const messages = {
    '0' : 'Not Great',
    '10' : 'Not Great',
    '20' : 'Not Great',
    '30' : 'Just OK',
    '40' : 'Just OK',
    '50' : 'Just OK',
    '60' : 'Average Shot',
    '70' : 'Average Shot',
    '80' : 'Great Shot!',
    '90' : 'Great Shot!',
    '100' : 'Amazing Shot!'
}

export const clubData = [
    {
        name: 'Driver',
        speed: 94,
        verticalAngle: 30,
        distance: 280
    },
    {
        name: '3 Wood',
        speed: 88.5,
        verticalAngle: 32,
        distance:240
    },
    {
        name: '5 Wood',
        speed: 84,
        verticalAngle: 34,
        distance: 210
    }
];