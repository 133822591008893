export const slugify = str => {

    return str.replace(/\s/g, '-').toLowerCase()
}

export const getCookie = function( name ){

    let cookieValue = null;

    if( document.cookie && document.cookie !== '' ){

        const cookies = document.cookie.split( ';' );

        for ( let i = 0; i < cookies.length; i++ ) {

            const cookie = cookies[ i ].trim();

            if ( cookie.substring( 0, name.length + 1 ) === ( name + '=' ) ) {
                
                cookieValue = decodeURIComponent( cookie.substring( name.length + 1 ) );

                break;
            }
        }
    }
    
    return cookieValue;
}

export const stateHandler = ( state, action, handlers ) => {

    let _state = { ...state }

    if( handlers[ action.type ] ){

        handlers[ action.type ]( _state, action );

        return _state;

    } else {

        throw new Error( `${ action.type }, handler not found` );
    }
}

export const setVarStateHandler = ( state, action ) => {

    let _state = { ...state }, _key = action.type.substring(3);
    
    _key = _key.charAt(0).toLowerCase() + _key.slice(1);

    if( _state[ _key ] === undefined ) throw new Error(`State key '${_key}' not found.`);

    _state[ _key ] = action.value;

    return _state;
}

export const getHeaders = ( method, use_api_token ) => {

    let headers = { 'Content-Type' : 'application/json' }
  
    if( use_api_token && ( [ 'POST', 'PATCH', 'DELETE' ].indexOf( method ) != -1 ) ){

      headers[ 'Authorization' ] = `Token ${ window.api_token }`;
    }
  
    var csrftoken = getCookie( 'csrftoken' );
  
    headers[ 'X-CSRFToken' ] = `${ csrftoken }`;
  
    return headers;
}

export const handleFetchResponse =  response => {

    if( response.ok ){

        try{

            return response.json();

        } catch( e ){

            throw new Error( e );
        }
    }

    if ( response.status >= 400 ){

        throw new Error( response.statusText );
    }
}
  
export const handleFetchError = error => {

    throw error;
}
