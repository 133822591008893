import React, { useEffect } from 'react';

import { useGameState } from '../../../provider/GameStateProvider';

const DGameRulesScreen = () => {

    const [ gameState ] = useGameState();

    useEffect( () => {

        window.gtag('event', 'screen_view', {
            'event_category' : 'screens',
            'event_label' : 'Rules'
        });
    }, [] )

    return (
        <div className="px-4 cover text-center d-flex flex-column align-items-center">
            <div className="p-3 bg-cover pos-fixed z-0 cover" style={{backgroundImage:`url('${ gameState.images.instructions.bg }')`}}>
                <div className="text-dark-violet bg-white text-left px-4 pos-rel pt-3 pb-2 d-flex flex-column align-items-start mt-4" style={{borderRadius:20, gap:6, maxWidth:350 }}>
                    <h5 className="medium bold">Game Rules:</h5>
                    <ul style={{paddingLeft:12}}>
                        <li className="mb-1"><h5 className="medium">You get one chance per hole to drive the outcome! Try to get as close to the pin as possible.</h5></li>
                        <li className="mb-1"><h5 className="medium">You will play three different holes, each worth 100 points.</h5></li>
                        <li><h5 className="medium">Score as close as you can to 300 to own the moment!</h5></li>
                    </ul>
                </div>
            </div>
            <img className="pos-abs z-1" style={{top:40, right:40}} src={ gameState.images.corebridge } width={200} />
        </div>
    )
}

export default DGameRulesScreen