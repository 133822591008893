/* React */
import React, { useEffect, useState } from 'react';

import PropTypes from "prop-types";

const SocketContext = React.createContext();

/* Methods */
const useSocketState = () => {

    const context = React.useContext( SocketContext );

    if( context === undefined ) throw new Error('useSocketState must be used within a SocketProvider');

    return context
}

/* Definition */
const SocketProvider = ( { room, children } ) => {

    const [ socket, setSocket ] = useState();

    const [ ready, setReady ] = useState( false );

    const onOpen = e => {

        console.log( 'Connected!' );
            
        console.log( e );

        setReady( true );
    }

    const onClose = e => {

        console.error('Chat socket closed unexpectedly');
    
        console.log( e );

        setReady( false );
    }

    useEffect( () => {

        let _socket = new WebSocket( 'wss://' + window.location.host.replace('8000','8001') + '/ws/chat/' + room + '/' );

        _socket.addEventListener( 'open', onOpen );

        _socket.addEventListener( 'close', onClose );
    
        setSocket( _socket );
    }, [] );
    
    return (
        <SocketContext.Provider value={ [ socket, ready ] }>
            { children }
        </SocketContext.Provider>
    )
}

/* Props */
SocketProvider.propTypes = {
    room: PropTypes.number.isRequired,
    children: PropTypes.oneOfType( [ PropTypes.array, PropTypes.object ] )
}

export { useSocketState, SocketProvider };
