import React, { useEffect } from 'react';

import PropTypes from "prop-types";

import { stateHandler } from '../util/helpers';

import { holeData, clubData } from '../data/game';

import { toRadians } from '../components/display/screen/DrawLib';

const GameStateContext = React.createContext();

const useGameState = () => {

    const context = React.useContext( GameStateContext );

    if( context === undefined ) throw new Error('useGameState must be used within a GameStateProvider');

    return context
}

const calcWindSpinFactor = ( speed, direction ) => {

    let directionFactor = Math.sin( toRadians( direction ) ) * -2;

    let speedFactor = speed / 4;

    let factor = Math.abs( directionFactor ) < .01 ? speedFactor : speedFactor * directionFactor;
    
    return factor;
}

const calcWindSpeedFactor = ( speed, direction ) => {

    let directionFactor = Math.cos( toRadians( direction ) );

    let speedFactor = speed / 4;

    let factor = Math.abs( directionFactor ) < .01 ? speedFactor : speedFactor * directionFactor;
    
    return factor;
}

const eventHandlers = {
    
    clubUp: ( state ) => {

        state.currentClubIndex = Math.min( clubData.length -1, state.currentClubIndex + 1 );
    },
    clubDown: ( state ) => {

        state.currentClubIndex = Math.max( 0, state.currentClubIndex - 1 );
    },
    setCurrentClub: ( state, action ) => {

        state.currentClub = action.club;
        state.shotControl = { ...state.shotControl, initVerticalAngleDegrees: state.currentClub.verticalAngle }

        state.shotControl.initSpinAngle = calcWindSpinFactor( state.wind.speed, state.wind.direction ) + state.shotControl.spinFactor;
        state.shotControl.initSpeedMPH = state.currentClub.speed + calcWindSpeedFactor( state.wind.speed, state.wind.direction ) + state.shotControl.speedFactor;
    },
    setCharacter: ( state, action ) => {

        state.character = action.character;
    },
    nextHole: ( state, action ) => {

        if( action.hole ){

            state.currentHoleIndex = action.hole - 1;
        } else {

            if( state.currentHoleIndex == null ){
    
                state.currentHoleIndex = 0;
            } else {
    
                state.currentHoleIndex++
            }
        }

        state.shotControl.speedFactor = 0;

        state.shotControl.spinFactor = 0;
        
        state.shotControl.initHorizontalAngleDegrees =  0;

        state.wind = {
            speed: Math.floor( Math.random() * 20 ),
            direction: Math.floor( Math.random() * 360 )
        }

        // state.wind = {
        //     speed: 0,
        //     direction: 0
        // }
    },
    setCurrentHole: ( state, action ) => {

        state.currentHole = action.hole;

        state.currentClubIndex = state.currentHole.club;

        state.shotControl.initSpinAngle = calcWindSpinFactor( state.wind.speed, state.wind.direction ) + state.shotControl.spinFactor;
        state.shotControl.initSpeedMPH = state.currentClub.speed + calcWindSpeedFactor( state.wind.speed, state.wind.direction ) + state.shotControl.speedFactor;
    },
    setShotStats: ( state, action ) => {

        state.shotStats = action.value
    },
    setShotControl: ( state, action ) => {

        state.shotControl = { ...state.shotControl, ...action.values }

        state.shotControl.initHorizontalAngleDegrees = Math.min( 5, Math.max( -5, state.shotControl.initHorizontalAngleDegrees ) );

        state.shotControl.initSpinAngle = calcWindSpinFactor( state.wind.speed, state.wind.direction ) + state.shotControl.spinFactor;
        state.shotControl.initSpeedMPH = state.currentClub.speed + calcWindSpeedFactor( state.wind.speed, state.wind.direction ) + state.shotControl.speedFactor;
    },
    holeComplete: ( state, action ) => {

        state.holes = [ ...state.holes ];
        state.holes[ state.currentHoleIndex ] = { ...state.holes[ state.currentHoleIndex ], result: { ...action.result } };
        state.totalScore = 0 ;
        for( let i = 0; i < state.holes.length; i++ ) state.totalScore += ( state.holes[ i ].result ? state.holes[ i ].result.score : 0 );
    },
    setHoleGroup: ( state, action ) => {

        state.holes[ action.i ] = { ...state.holes[ action.i ], group: action.group };
    },
    startOver: ( state ) => {
        state.currentHoleIndex = null;
        state.totalScore = 0;
        state.holes = [ ...state.holes ];
        for( let i = 0; i < state.holes.length; i++ ) state.holes[ i ] = { ...state.holes[ i ], result: null };
    },
    setShotPlaying: ( state, action ) => {

        state.shotPlaying = action.value;
    },
    setPhoneConnected: ( state, action ) => {

        state.phoneConnected = action.value
    }
}

const GameStateProvider = ( { children, session, models, scene_container_el, images } ) => {

    const default_wind = {
        speed: Math.floor( Math.random() * 30 ),
        direction: Math.floor( Math.random() * 360 )
    }

    const [ state, dispatch ] = React.useReducer( ( state, action ) => stateHandler( state, action, eventHandlers ), { 
        phoneConnected: false,
        wind: default_wind,
        clubs: clubData,
        currentClubIndex: 0,
        currentClub: null,
        character: null, 
        models, 
        session, 
        scene_container_el, 
        images,
        currentHoleIndex: null,
        currentHole: null,
        totalScore: 0,
        holes: holeData,
        shotStats : {
            time:0,
            speed:0,
            height:0,
            distance_from_center:0,
            shot_distance:0,
            spin:0
        },
        shotControl:{
            dt: 0.001,
            displaySpeed: 1,
            initSpeedMPH: 90,
            speedFactor:0,
            spinFactor:0,
            initVerticalAngleDegrees: 30,
            initHorizontalAngleDegrees: 0,
            initBackspinRPM: 6000,
            initSpinAngle: 0
        }
    } );

    useEffect(()=>{

        if( state.currentHoleIndex != null ) dispatch( { type:'setCurrentHole', hole: state.holes[ state.currentHoleIndex ] } );

    }, [ state.currentHoleIndex, state.holes ] )

    useEffect(()=>{

        dispatch( { type:'setCurrentClub', club: state.clubs[ state.currentClubIndex ] } );

    }, [ state.currentClubIndex, state.clubs ] )

    useEffect( () => {

        console.log("SPEED changed",state.shotControl.initSpeedMPH)

    }, [ state.shotControl.initSpeedMPH ] )

    useEffect( () => {

        console.log("SPIN changed",state.shotControl.initSpinAngle)

    }, [ state.shotControl.initSpinAngle ] )

    return (
        <GameStateContext.Provider value={ [ state, dispatch ] }>
            { children }
        </GameStateContext.Provider>
    )
}

GameStateProvider.propTypes = {
    scene_container_el: PropTypes.object,
    session: PropTypes.object,
    models: PropTypes.object,
    images: PropTypes.object,
    children: PropTypes.oneOfType( [ PropTypes.array, PropTypes.object ] )
}

export { GameStateProvider, useGameState };