import React, { useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router';

import { useSocketState } from '../../../provider/SocketProvider';

import { useGameState } from '../../../provider/GameStateProvider';

const DHomeScreen = () => {

    const navigate = useNavigate();

    const [ socket, socketReady ] = useSocketState();

    const [ gameState, gameDispatch ] = useGameState();

    const location = useLocation();

    useEffect( () => {
        
        console.log( "Game State Changed:" );

        console.log( gameState );

    }, [ gameState ] );

    useEffect( () => {

        if( socketReady == true ){

            socket.addEventListener( 'message', function ( event ) {

                let data = JSON.parse( event.data );

                if( data.message == 'welcome' ){

                    if( location.pathname == '/' ) navigate( '/game', {replace:true} );
                }

                if( data.message == 'rules' ){

                    if( location.pathname == '/' ) navigate( '/game/rules', {replace:true} );
                }

                if( data.message == 'character' ){

                    navigate( '/game/character', {replace:true} );
                }

                if( data.message == 'play' ){

                    navigate( '/game/play', {replace:true} );
                }

                if( data.message.character ){
                    
                    gameDispatch( { type: 'setCharacter', character: data.message.character } );
                }

                if( data.message == 'startover' ){

                    navigate('/');
                }

                if( data.message == 'userconnected' ){

                    console.log('userconnected');

                    gameDispatch( { type: 'setPhoneConnected', value:true } );
                }

                if( data.message == 'userdisconnected' ){

                    console.log('user disconnected');

                    gameDispatch( { type: 'setPhoneConnected', value:false } );
                }
            } );
        }

    }, [ socketReady ] )

    return (
        <div className="px-4 cover d-flex bg-cover pos-rel justify-content-center" style={{backgroundImage:`url('${ gameState.images.home_bg }')`}}>
            <div className="pos-abs p-4 tl-0 ml-1 hide-mobile" >
                <img className="mt-1" src={ gameState.images.own_the_moment } width={240} />
            </div>
            <div className="pos-abs p-4 tr-0 mr-1" style={{width:320}}>
                <img src={ gameState.images.corebridge } width={230} />
            </div>
            <div className="d-flex flex-column align-items-center text-white justify-content-center cover" style={{gap:40}}>
                <div className="d-flex flex-column align-items-center text-white hide-mobile" style={{gap:20}}>
                    <h5 className="regular mt-n4">SCAN TO PLAY</h5>
                    <div className="bg-white d-flex align-items-center justify-content-center" style={{borderRadius:20, overflow:'hidden'}}>
                        <img width={280} src={ gameState.session.qrcode } />
                    </div>
                </div> 
                <div className="show-mobile px-1 text-left">
                    {/* <img src={ gameState.images.aoa_golf } width={240}  /> */}
                    <h4 className="regular mb-3">Please visit this page on your laptop or tablet to begin.</h4>
                    <img src={ gameState.images.own_the_moment } width={240} />
                </div>
            </div>
            <div className="pos-abs bl-0 p-4 text-white" style={{fontSize:11}}>
                <p className="mb-1">Please connect your phone to Wi-Fi for the best experience.</p>
                <p>Issuer: American General Life Insurance Company (AGL). This product is not available in New York.</p>
                <p>For financial professional use only. Not for use with the general public.</p>
            </div>
        </div>
    )
}

export default DHomeScreen
