import React from "react";
import { render } from "react-dom";
import { MemoryRouter as Router } from "react-router-dom";
import Display from "./components/Display";
import './css/display.scss';

console.log(window.props)

render(
  <Router>
    <Display {...window.props} />
  </Router>,
  document.getElementById('root') 
);