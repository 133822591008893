import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import { Routes, Route } from "react-router-dom";

import DHomeScreen from './display/screen/DHomeScreen';

import DGameScreen from './display/screen/DGameScreen';

import { SocketProvider } from './../provider/SocketProvider';

import { GameStateProvider } from '../provider/GameStateProvider';

import { ResizeProvider } from '../provider/ResizeProvider';

const Display = ( { session, models, images } ) => {

    const scene_container_el = useRef();

    return(
        <>
            <div ref={ scene_container_el } className="pos-fixed cover z-0 bg-cover" style={{backgroundImage:`url('${ window.props.images.grasstile }')`}}></div>
            <div className="display-app pos-rel z-1 cover" style={{visibility:'visible'}}>
                <SocketProvider room={ session.uid } >
                    <GameStateProvider session={session} models={ models } scene_container_el={ scene_container_el } images={ images }>
                        <ResizeProvider>
                            <Routes>
                                <Route path="/game/*" element={ <DGameScreen /> } />
                                <Route exact path="/" element={ <DHomeScreen /> } />
                            </Routes>
                        </ResizeProvider>
                    </GameStateProvider>
                </SocketProvider>
            </div>
        </>
    )
}

Display.propTypes = {
    session:PropTypes.object,
    models: PropTypes.object,
    images: PropTypes.object
}

export default Display
