import React, {useEffect} from 'react';

import { useGameState } from '../../../provider/GameStateProvider';

const DGameCharacterScreen = () => {

    const [ gameState, gameDispatch ] = useGameState();

    useEffect( () => {

        gameDispatch( { type:'startOver' } );

        window.gtag('event', 'screen_view', {
            'event_category' : 'screens',
            'event_label' : 'Choose Character'
        });
 
     }, [] )

    return (
        <div className="px-4 cover text-center">
            <div className="text-white p-4">
                <img src={ gameState.images.corebridge } width={260} />
            </div>
            <div className="d-flex p-4 mx-auto" style={{gap:20, maxWidth:800}}>
                <div className="flex-grow-1">
                    <div className="shadow bg-cover w-100 ar-100 bg-light-violet" style={{borderRadius: '50%', border:`10px solid ${ gameState.character == 'male' ? '#C053EF' : '#E0E0FB' }`, backgroundRepeat:'no-repeat', backgroundSize:'80%', backgroundPosition:'bottom', backgroundImage:`url('${gameState.images.male}')` }} ></div>
                </div>
                <div className="flex-grow-1">
                    <div className="shadow bg-cover w-100 ar-100 bg-light-violet" style={{borderRadius: '50%', border:`10px solid ${ gameState.character == 'female' ? '#C053EF' : '#E0E0FB' }`, backgroundRepeat:'no-repeat', backgroundSize:'70%', backgroundPosition:'bottom', backgroundImage:`url('${gameState.images.female}')` }} ></div>
                </div>
            </div> 
        </div>
    )
}

export default DGameCharacterScreen
