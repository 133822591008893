/* React */
import React, { useEffect } from 'react';

import PropTypes from "prop-types";

import { setVarStateHandler } from '../util/helpers';

/* Utils */

const ResizeStateContext = React.createContext();

/* Methods */
const useResizeState = () => {

    const context = React.useContext( ResizeStateContext );

    if( context === undefined ) throw new Error('useResizeState must be used within a ResizeProvider');

    return context
  }

/* Definition */
const ResizeProvider = ( { children } ) => {

    const [ state, dispatch ] = React.useReducer( setVarStateHandler, { size: { width: window.innerWidth, height: window.innerHeight } } );

    useEffect(()=>{

        console.log("init resize provider")

        window.addEventListener('resize', e => {

            dispatch( { type:'setSize', value:{ width: e.target.innerWidth, height: e.target.innerHeight } } )
        })
    },[])

    return (
        <ResizeStateContext.Provider value={ [ state, dispatch ] }>
            { children }
        </ResizeStateContext.Provider>
    )
}

/* Props */
ResizeProvider.propTypes = {

    children: PropTypes.oneOfType( [ PropTypes.array, PropTypes.object ] )
}

export { ResizeProvider, useResizeState };
