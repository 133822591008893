
const toMPS = (mph) => {
    return mph * 0.44704;
}

const toRadians = ( degrees ) => {
    return degrees * ( Math.PI / 180 );
}
    
const toDegrees = ( radians ) => {
    return radians * ( 180 / Math.PI );
}

const toMPH = (mps) => {
    return mps * 2.23694;
}
  
const toMeters = (yards) => {
    return yards * 0.9144;
}
  
const toYards = (meters) => {
    return meters * 1.09361;
}
  
const toRPM = (rps) => {
    return rps * 9.54929659;
}

const getRotatedPosition = ( radius, angle ) => {

    let x = radius * Math.sin( Math.PI * 2 * angle / 360 );

    let y = radius * Math.cos( Math.PI * 2 * angle / 360 );

    return [ Math.round( x * 100 ) / 100, Math.round( y * 100 ) / 100 ]
}

export { getRotatedPosition, toMPS, toMeters, toYards, toRPM, toMPH, toRadians, toDegrees }