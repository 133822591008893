import React from 'react';

import DGameCharacterScreen from './DGameCharacterScreen';

import { Route, Routes } from 'react-router';

import DGameWelcomeScreen from './DGameWelcomeScreen';

import DGamePlayScreen from './DGamePlayScreen';

import DGameOverScreen from './DGameOverScreen';

import { useGameState } from '../../../provider/GameStateProvider';

import DGameRulesScreen from './DGameRulesScreen';

const DGameScreen = () => {

    const [ gameState ] = useGameState();

    return (
        <div className="text-center cover">
            { !gameState.phoneConnected && <div className="pos-fixed p-2 bg-white z-5">
                <h6>{ "Lost Phone Connection" }</h6>
                <a href="/">Start Over</a>
            </div> }
            <div className="mx-auto w-100 cover">
                <Routes>
                    <Route exact path="/character" element={ <DGameCharacterScreen /> } />
                    <Route exact path="/play" element={ <DGamePlayScreen /> } />
                    <Route exact path="/over" element={ <DGameOverScreen /> } />
                    <Route exact path="/" element={ <DGameRulesScreen /> } />
                    <Route exact path="/rules" element={ <DGameWelcomeScreen /> } />
                </Routes>
            </div>
        </div>
    )
}

export default DGameScreen;
