import React, { useEffect } from 'react';

import { useGameState } from '../../../provider/GameStateProvider';

const DGameOverScreen = () =>{

    const [ gameState ] = useGameState();

    useEffect( () => {
        
        window.gtag('event', 'screen_view', {
            'event_category' : 'screens',
            'event_label' : 'Game Over'
        } );
        
        setTimeout( () => window.open( '/', '_self' ), 90000 )
    }, [] )

    return (
        <div className="cover text-center d-flex flex-column overflow-y-auto">
            <div className="pos-rel pt-4 d-flex flex-column" style={{minHeight:'100%'}}>
                <img className="pos-abs z-1" style={{top:20, right:30}} src={ gameState.images.corebridge } width={200} />
                <div className="text-white d-flex flex-column align-items-center justify-content-center" style={{ gap: 20, paddingTop:100, paddingBottom:150 }}>
                    <h4 className="medium lh-1">Congratulations! You scored</h4>
                    <h1 className="bold bg-orchid" style={{padding:'10px 40px 6px 40px', borderRadius:50}}>{ gameState.totalScore }</h1>
                    <h4 className="medium lh-1">out of 300 points.</h4>
                    <h4 className="medium lh-1">Thank you for playing.</h4>
                </div>
                <div className="flex-grow-1 d-flex flex-column text-violet align-items-center bg-white" style={{gap:10}}>
                    <div className="w-100 bg-white flex-grow-1 p-2" style={{borderRadius:40, marginTop:-80}}>
                        <h3 className="bold text-violet" style={{padding:'10px 40px 6px 40px', borderRadius:50}}>Learn more about <br /> Advanced Outcomes Annuity<sup style={{marginLeft:2, fontSize:14}}>®</sup></h3>
                        {/* <div className="p-2">
                            <img src={ gameState.images.own_the_moment_k } width={230} />
                        </div> */}
                        <div className="d-flex justify-content-center pt-3 pb-1" style={{gap:10}}>
                            <a style={{ borderRadius:40, textDecoration:'none' }} className="small medium text-white bg-violet px-3 py-2 d-inline-block" href="https://edge6sun.jaggedpeak.com/merchantUploads/edgeSunAmerica/Advanced%20Outcomes%20Annuity%20Client%20Brochure_T6197BR1_0922_ADA.pdf">Download the brochure</a>
                            <a style={{ borderRadius:40, textDecoration:'none' }} className="small medium text-white bg-violet px-3 py-2 d-inline-block" href="/">Play again</a>
                            <a style={{ borderRadius:40, textDecoration:'none' }} className="small medium text-white bg-violet px-3 py-2 d-inline-block" href="http://aoannuity.com/">Visit the website</a>
                        </div>
                    </div>
                    <div className="bg-light-gray px-4 pt-3">
                        <div className="text-dark-gray" style={{textAlign:'left'}}>
                            <p style={{marginBottom:20, fontSize:12}}><strong>Advanced Outcomes Annuity offers structured outcome and equity-linked investment strategies with underlying funds that have characteristics unlike many other traditional investment products and may not be suitable for all investors. There is no guarantee that the outcomes for a specific fund term will be realized. An investment in Advanced Outcomes Annuity involves investment risk, including possible loss of principal. For more information regarding whether an investment in these funds is right for you, please see the product and fund prospectuses.</strong></p>
                            <p style={{marginBottom:20, fontSize:12}}><i>Advanced Outcomes Annuity is sold by prospectus only. The prospectus contains the investment objectives, risks, fees, charges, expenses and other information regarding the contract and underlying funds, which should be considered carefully before investing. A prospectus may be obtained by calling 1-877-445-1262. Investors should read the prospectus carefully before investing.</i></p>
                            <p style={{marginBottom:20, fontSize:10}}>Contracts and features may vary by state or may not be available in all states.</p>
                            <p style={{marginBottom:20, fontSize:10}}>Advanced Outcomes Annuity is issued by <strong>American General Life Insurance Company (AGL)</strong>, Houston, TX, in all states except New York. AGL does not solicit, issue or deliver policies or contracts in the state of New York. <strong>Distributed by AIG Capital Services, Inc. (ACS),</strong> member FINRA. All companies above are subsidiaries of Corebridge Financial, Inc. Corebridge Financial and Corebridge are marketing names used by subsidiaries of Corebridge Financial, Inc.</p>
                        </div>
                        <div className="w-100 pb-2 d-flex text-left text-dark-gray align-items-start">
                            <div className="flex-grow-1 pr-4">
                                <p style={{fontSize:10}}>For financial professional use only. Not for use with the general public.</p>
                                <p style={{fontSize:10}}>© 2023. Corebridge Financial, Inc. All rights reserved.<br />Corebridgefinancial.com<br />Policy form number: AG-807 (06/21)<br />A6197GME (1/23)</p>
                            </div>
                            <div>
                                <div className="d-flex flex-column" style={{border:'1px solid #666'}}>
                                    <div style={{textAlign:'center', padding:4, borderBottom:'1px solid #666'}}>
                                        <p style={{fontSize:8}}><strong>Not FDIC or NCUA/NCUSIF Insured</strong></p>
                                    </div>
                                    <div style={{textAlign:'center', padding:4}}>
                                        <p style={{fontSize:8, marginBottom:0}}><strong>May Lose Value • No Bank or Credit Union Guarantee</strong></p>
                                        <p style={{fontSize:8}}><strong>Not a Deposit • Not Insured by any Federal Government Agency</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default DGameOverScreen
