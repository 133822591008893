import React, { useEffect, useState, useRef } from 'react';

import { Object3D,SpotLight,BufferGeometry,Clock,Scene,Color,LoopOnce,WebGLRenderer,PCFSoftShadowMap,PMREMGenerator, PerspectiveCamera,DirectionalLight,AmbientLight,PointLight,TextureLoader,BackSide,BoxGeometry,Group,SphereGeometry,MeshBasicMaterial,Mesh,SkeletonHelper,AnimationMixer,RepeatWrapping,MeshLambertMaterial,PlaneBufferGeometry} from 'three';

import { MeshLine, MeshLineMaterial } from 'three.meshline';

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

import { useResizeState } from '../../../provider/ResizeProvider';

import { useSocketState } from './../../../provider/SocketProvider';

import { useGameState } from '../../../provider/GameStateProvider';

import { toRadians, toYards, toMPH, toRPM, getRotatedPosition } from './DrawLib';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import { gsap } from 'gsap';

import Shot from './Shot';

import { useNavigate } from 'react-router';

import WindIcon from '../../global/WindIcon';

import { messages } from '../../../data/game';

const getSplinedLine = ( points ) => {

    const geometry = new BufferGeometry().setFromPoints( points );

    const line = new MeshLine();

    line.setGeometry( geometry );

    const material = new MeshLineMaterial({
        color: 0xffff00,
        opacity:.25,
        transparent:true,
        lineWidth: .2
    })

    const mesh = new Mesh( line, material );

    return mesh;
}

const DGamePlayScreen = () => {

    const navigate = useNavigate();

    const [ socket, socketReady ] = useSocketState();

    const [ resizeState ] = useResizeState();

    const [ gameState, gameDispatch ] = useGameState();

    const [ modelReady, setModelReady ] = useState( false );

    const [ holeReady, setHoleReady ] = useState( false );

    const [ holesLoaded, setHolesLoaded ] = useState( { '1':false, '2':false, '3':false} )

    const [ gameReady, setGameReady ] = useState( false );

    const [ orientation, setOrientation ] = useState( 0 );

    const [ practicing, setPracticing ] = useState( true );

    const practicing_ref = useRef( true );

    const [ loadProgress, setLoadProgress ] = useState( 0 );

    const load_progress_ref = useRef( loadProgress );

    const updateLoadProgress = ( p ) => {

        setLoadProgress( p );
        load_progress_ref.current = p;
    }

    const camera = useRef();
    
    const renderer = useRef();

    const scene = useRef();

    const displayStartTime = useRef();

    const ball = useRef();

    const clock = useRef();

    const player_group = useRef();

    const marker_1 = useRef();

    const marker_2 = useRef();

    const mixer = useRef();

    const slight = useRef();

    const animations = useRef();

    const player = useRef();

    const action = useRef();

    const default_camera_position = {  x: -1,  y: 20, z: -30 }

    const camera_position = useRef( default_camera_position );

    const camera_target = useRef( {  x: 0,  y: 0, z: 30 } );

    const shot = useRef();

    const points = useRef();

    const line = useRef();

    const gameState_ref = useRef( gameState );

    const hole_group = useRef();

    const hole_1_group = useRef();

    const hole_2_group = useRef();

    const hole_3_group = useRef();

    const power_el = useRef();

    const player_position_y = useRef( -.5 );

    const resetCamera = () => {

        gsap.to( camera_target.current, {x:0, y:0, z:30, duration:0, ease:'sine.inOut', overwrite:true });

        gsap.to( camera_position.current, { x:default_camera_position.x, y:default_camera_position.y, z:default_camera_position.z, duration:0, ease:'sine.inOut', overwrite:true });
    }

    const resetShot = () => {
        
        gameDispatch( { type: 'setShotPlaying', value: false } );

        resetCamera();

        if( line.current ) scene.current.remove( line.current );

        points.current = [];

        shot.current = null;
    }

    const beginShot = () => {

        resetShot();

        gameDispatch( { type: 'setShotPlaying', value: true } );

        shot.current = new Shot( { ...gameState_ref.current.shotControl, initHorizontalAngleDegrees:0 } );

        let { x, y, z } = shot.current.points[ shot.current.points.length - 1 ].position;

        gsap.to( camera_target.current, {x, y:y+50, z:z+5, delay:1, duration:10, ease:'sine.inOut', overwrite:true });

        gsap.to( camera_position.current, { x:x, y:y+150, z:z, delay:5, duration: 6, ease:'sine.inOut', overwrite:true });

        let point = shot.current.points[ 0 ],

        convertedPosition = point.position.clone().multiplyScalar( toYards( 1 ) );

        points.current.push( convertedPosition );

        displayStartTime.current = Date.now();
    }

    const getShotResults = ( pos ) => {

        let [ r_posx, r_posz ] = getRotatedPosition( gameState_ref.current.currentHole.center.z, gameState_ref.current.shotControl.initHorizontalAngleDegrees );

        let x_dist = Math.abs( pos.x - r_posx );

        let z_dist = Math.abs( pos.z - r_posz );

        let distance_from_center = Math.sqrt( ( x_dist * x_dist ) + ( z_dist * z_dist ) );

        let hit_rings = gameState_ref.current.currentHole.rings.filter( r => ( distance_from_center <= r.radius ) );

        let best_ring = hit_rings && hit_rings.length > 0 ? hit_rings[ hit_rings.length -1 ] : null;

        let shot_distance = Math.floor( Math.sqrt( ( pos.z * pos.z ) + ( pos.x * pos.x ) ) );

        let score = best_ring ? best_ring.score : 0;

        let message = messages[ String( score ) ];

        let result = {
            score,
            is_good: distance_from_center < gameState_ref.current.currentHole.good_threshold,
            distance_from_center,
            shot_distance,
            pos,
            message
        }

        return result;
    }
    
    const updateShot = () => {

        if( shot.current ){

            let now = Date.now();

            let rawTimeElapsed = now - displayStartTime.current;

            let displayTimeElapsed = Math.floor( gameState_ref.current.shotControl.displaySpeed * rawTimeElapsed );

            if( displayTimeElapsed <= shot.current.points.length ) {       

                let point = shot.current.points[ displayTimeElapsed ];

                if( point == null ) return;

                let convertedPosition = point.position.clone().multiplyScalar( toYards( 1 ) );

                points.current.push( convertedPosition );

                if( line.current ) scene.current.remove( line.current );

                line.current = getSplinedLine( points.current );

                scene.current.add( line.current );

                ball.current.position.x = convertedPosition.x;

                ball.current.position.y = convertedPosition.y;

                ball.current.position.z = convertedPosition.z;

                gameDispatch( { type:'setShotStats', value: {
                    time: ( displayTimeElapsed / 1000 ).toFixed( 1 ),
                    speed: toMPH( point.velocity.length()).toFixed( 1 ),
                    height: Math.floor( convertedPosition.y.toFixed(0) * 3 ),
                    distance: Math.floor( Math.sqrt( ( convertedPosition.z * convertedPosition.z ) + ( convertedPosition.x * convertedPosition.x ) ) ),
                    spin: toRPM( point.angularVelocity.length() ).toFixed( 0 )
                } } );

            } else {

                if( gameState_ref.current.shotPlaying ){

                    gameDispatch( { type: 'setShotPlaying', value: false } );

                    let convertedPosition = shot.current.points[ shot.current.points.length-1 ].position.clone().multiplyScalar( toYards( 1 ) );

                    let result = getShotResults( convertedPosition );

                    setTimeout( resetShot, 2200 );

                    if( result.is_good ){
                        goodshot();
                    } else {
                        badshot();
                    }

                    gameDispatch({ type: 'holeComplete', result });

                    setPracticing( true );

                    practicing_ref.current = true;

                    if( gameState_ref.current.currentHoleIndex == gameState_ref.current.holes.length - 1 ){
                        
                        setTimeout( () => {

                            sendMessage( { type: 'gameOver', totalScore:gameState_ref.current.totalScore } );
            
                            navigate('/game/over');
                        }, 5000 )
                    }
                }
            }
        } else {

            ball.current.position.x = 0;

            ball.current.position.y = 0;

            ball.current.position.z = 0;
        }
    }

    const onResize = () => {
        
        if( camera.current ) {

            camera.current.aspect = gameState.scene_container_el.current.clientWidth / gameState.scene_container_el.current.clientHeight;

            camera.current.updateProjectionMatrix();
        }

        if( renderer.current ) {

            renderer.current.setSize( gameState.scene_container_el.current.clientWidth, gameState.scene_container_el.current.clientHeight );
        }
    }

    const toSwingPosition = ( p ) => {

        if( animations.current && !gameState_ref.current.shotPlaying ){

            if( action.current ) action.current.stop();

            let a = animations.current.find( a => a.name == 'swing' );

            if( mixer.current ){

                action.current = mixer.current.clipAction( a ) ;

                action.current.play();

                mixer.current.timeScale = 1;

                let t = 1 + ( .3 * p ) ;

                t = Math.max( Math.min( 1.3, t ), 1 );

                mixer.current.setTime( t );

                mixer.current.timeScale = 0;

                // if( ( t >= 1.185 ) && !practicing && inSwing ){

                //     swing();
                // }
            }
        }
    }

    const idle = () => {

        if( animations.current ){

            if( action.current ) action.current.stop();

            let a = animations.current.find( a => a.name == 'swing' );

            if( mixer.current ){

                action.current = mixer.current.clipAction( a ) ;

                mixer.current.timeScale = 1;
                
                mixer.current.setTime( 1 );

                mixer.current.timeScale = 0;

                action.current.play();
            }
        }
    }

    const swing = () => {

        if( animations.current && !gameState_ref.current.shotPlaying ){

            let a = animations.current.find( a => a.name == 'swing' );

            if( mixer.current ){

                action.current = mixer.current.clipAction( a ) ;

                action.current.loop = LoopOnce

                action.current.clampWhenFinished = true;

                mixer.current.timeScale = 1;

                mixer.current.setTime( 1.2 );

                action.current.play();

                beginShot()
            }
        }
    }

    const goodshot = () => {

        if( animations.current ){

            if( action.current ) action.current.stop();

            let a = animations.current.find( a => a.name == 'goodshot' );

            if( mixer.current ){

                action.current = mixer.current.clipAction( a ) ;

                action.current.loop = LoopOnce

                action.current.clampWhenFinished = true;

                mixer.current.timeScale = 1;

                action.current.play();
            }
        }
    }

    const badshot = () => {

        if( animations.current ){

            if( action.current ) action.current.stop();

            let a = animations.current.find( a => a.name == 'badshot' );

            if( mixer.current ){

                action.current = mixer.current.clipAction( a ) ;

                action.current.loop = LoopOnce

                action.current.clampWhenFinished = true;

                mixer.current.timeScale = 1;

                action.current.play();
            }
        }
    }

    const renderScene = () => {

        requestAnimationFrame( renderScene );

        if( camera.current ){

            camera.current.position.x = camera_position.current.x;

            camera.current.position.y = camera_position.current.y;

            camera.current.position.z = camera_position.current.z;

            camera.current.lookAt( camera_target.current.x , camera_target.current.y , camera_target.current.z );
        }

        if( hole_group.current ) hole_group.current.rotation.y = -toRadians( gameState_ref.current.shotControl.initHorizontalAngleDegrees );

        if( hole_2_group.current ) hole_2_group.current.rotation.y = -toRadians( gameState_ref.current.shotControl.initHorizontalAngleDegrees );

        if( hole_3_group.current ) hole_3_group.current.rotation.y = -toRadians( gameState_ref.current.shotControl.initHorizontalAngleDegrees );

        if( mixer.current && clock.current ) mixer.current.update( clock.current.getDelta() );

        if( renderer.current && scene.current && camera.current ) renderer.current.render( scene.current, camera.current );

        if( player_group.current ){

            player_group.current.position.y = player_position_y.current;
        } 

        updateShot();
    }

    const animationFinished = e => {

        let _action = e.action;
        
        let _clip = _action.getClip();
        
        if( _clip.name != 'idle' && _clip.name != 'swing' ){

            if( gameState_ref.current.currentHoleIndex == gameState_ref.current.holes.length - 1 ){
                
                sendMessage( { type: 'gameOver', totalScore:gameState_ref.current.totalScore } );

                navigate('/game/over');
                
            } else {

                idle();
            }
        }
    }

    const buildHoleSkybox = ( h ) => {

        const materialArray = [];

        const posz = new TextureLoader().load( gameState.images[h].posz );

        materialArray.push( new MeshBasicMaterial( { map: posz, side: BackSide } ) );

        const negz = new TextureLoader().load( gameState.images[h].negz );

        materialArray.push( new MeshBasicMaterial( { map: negz, side: BackSide } ) );

        const posy = new TextureLoader().load( gameState.images[h].posy );

        materialArray.push( new MeshBasicMaterial( { map: posy, side: BackSide } ) );

        const negy = new TextureLoader().load( gameState.images[h].negy );

        materialArray.push( new MeshBasicMaterial( { map: negy, side: BackSide } ) );

        const negx = new TextureLoader().load( gameState.images[h].negx );

        materialArray.push( new MeshBasicMaterial( { map: negx, side: BackSide } ) );

        const posx = new TextureLoader().load( gameState.images[h].posx );

        materialArray.push( new MeshBasicMaterial( { map: posx, side: BackSide } ) );

        const skyboxGeo = new BoxGeometry( 1200, 1200, 1200);

        const skybox = new Mesh( skyboxGeo, materialArray );

        return skybox;
    }

    const buildHole1 = () => {

        marker_1.current.position.y = 1;

        marker_2.current.position.y = 0;

        player_position_y.current = -.4;

        if( holesLoaded[ '1' ] == true ){

            setHoleReady( true );
        } else {

            const skybox = buildHoleSkybox( 'hole1' );

            hole_1_group.current.add( skybox );

            const floor_texture = new TextureLoader().load( gameState.images.hole1.grasstile , t => {
                t.wrapS = t.wrapT = RepeatWrapping;
                t.offset.set( 0, 0 );
                t.repeat.set( 20, 20 );
            } );
    
            const floor_material = new MeshLambertMaterial( { map: floor_texture } );
    
            let floor = new Mesh( new PlaneBufferGeometry( 1500, 1500 ), floor_material );
    
            floor.rotation.x = -Math.PI / 2;
    
            floor.position.y = -20;

            hole_1_group.current.add( floor );

            const hole_loader = new FBXLoader();

            // temp

            // let { center, rings } = gameState.holes[ 0 ];

            // const group = new Group();

            // group.position.y = 3;

            // group.position.x = center.x

            // group.position.z = center.z

            // rings.forEach( (ring, i) => {

            //     const geometry = new CircleGeometry( ring.radius, 32 );

            //     const material = new MeshBasicMaterial( { color: ring.color, transparent:true, opacity:.08 } );

            //     const circle = new Mesh( geometry, material );

            //     circle.rotation.x = -Math.PI / 2;

            //     circle.position.set( 0 , ( i*.1 ) , 0 );

            //     group.add( circle )
            // });

            // hole_1_group.current.add( group );
            //Temp

            hole_loader.load( 
                gameState.models[ 'hole1' ],
                object => {
                    object.traverse(function ( child ) {

                        if ( child.isMesh ) {

                            if( child.name == 'plants_geo' ){

                                child.material.depthWrite = false;
                            }

                            child.receiveShadow = true;

                            child.castShadow = true;

                            if( child.name == 'water_geo' ){

                                const water_texture = new TextureLoader().load( gameState.images.hole1.water , t => {

                                    t.wrapS = t.wrapT = RepeatWrapping;

                                    t.offset.set( 0, 0 );

                                    t.repeat.set( 6, 6 );
                                } );
                        
                                const water_material = new MeshLambertMaterial( { map: water_texture, transparent:true, opacity:.4 } );

                                child.material = water_material;
                            }
                        } 
                    });

                    object.scale.set(.14,.14,.14);

                    object.position.y = 11.5;

                    object.position.x = -15;

                    object.position.z = 42;

                    object.rotation.y = toRadians( -208 );

                    hole_1_group.current.add( object );

                    setHoleReady( true );

                    setHolesLoaded( { ...holesLoaded, '1': true } );
                },
                xhr => {

                    updateLoadProgress( ( xhr.loaded / xhr.total ) * 100 )
                },
                error => {

                    console.log( error )
                }
            );
        }
    }

    const buildHole2 = () => {

        marker_1.current.position.y = -.5;

        marker_2.current.position.y = .9;

        player_position_y.current = .4;

        if( holesLoaded[ '2' ] == true ){

            setHoleReady( true );
        } else {
            
            const skybox = buildHoleSkybox( 'hole2' );

            skybox.position.z = 0;

            skybox.position.y = -20;

            skybox.rotation.y = toRadians( -60 );

            hole_2_group.current.add( skybox );

            const hole_loader = new FBXLoader();

            const floor_texture = new TextureLoader().load( gameState.images.hole1.grasstile , t => {
                t.wrapS = t.wrapT = RepeatWrapping;
                t.offset.set( 0, 0 );
                t.repeat.set( 20, 20 );
            } );

            const floor_material = new MeshLambertMaterial( { map: floor_texture } );
    
            let floor = new Mesh( new PlaneBufferGeometry( 1500, 1500 ), floor_material );
    
            floor.rotation.x = -Math.PI / 2;
    
            floor.position.y = -10;

            hole_2_group.current.add( floor );

            hole_loader.load( 
                gameState.models[ 'hole2' ],
                object => {

                    object.traverse( function ( child ) {

                        console.log( child );

                        if ( child.isMesh ) {

                            if( child.name == 'plants_geo' ){

                                child.material.depthWrite = false;
                            }

                            child.receiveShadow = true;

                            child.castShadow = true;
                        }

                        if( child.name == 'water_geo' ){

                            const water_texture = new TextureLoader().load( gameState.images.hole2.water , t => {

                                t.wrapS = t.wrapT = RepeatWrapping;

                                t.offset.set( 0, 0 );

                                t.repeat.set( 6, 6 );
                            } );
                    
                            const water_material = new MeshLambertMaterial( { map: water_texture, transparent:true, opacity:.4 } );

                            child.material = water_material;
                        }
                    } );

                    object.scale.set(.095,.095,.095);

                    object.position.y = 9.8;

                    object.position.x = -28;

                    object.position.z = 22;

                    object.rotation.y = toRadians( -190 );

                    hole_2_group.current.add( object );

                    setHoleReady( true );

                    setHolesLoaded( { ...holesLoaded, '2': true } );
                },
                xhr => {

                    updateLoadProgress( (xhr.loaded / xhr.total) * 100 );
                },
                error => {

                    console.log(error)
                }
            );
        }
    }

    const buildHole3 = () => {

        marker_1.current.position.y = .1;

        marker_2.current.position.y = .1;

        player_position_y.current = -.5;

        slight.current.intensity = 2;
        
        slight.current.angle = Math.PI/5;

        if( holesLoaded[ '3' ] == true ){

            setHoleReady( true );
        } else {
            
            const skybox = buildHoleSkybox( 'hole3' );

            skybox.position.z = 0;

            skybox.position.y = 20;

            skybox.rotation.y = toRadians( 170 );

            hole_3_group.current.add( skybox );

            const floor_texture = new TextureLoader().load( gameState.images.hole3.grasstile , t => {
                t.wrapS = t.wrapT = RepeatWrapping;
                t.offset.set( 0, 0 );
                t.repeat.set( 20, 20 );
            } );

            const floor_material = new MeshLambertMaterial( { map: floor_texture } );
    
            let floor = new Mesh( new PlaneBufferGeometry( 1500, 1500 ), floor_material );
    
            floor.rotation.x = -Math.PI / 2;
    
            floor.position.y = -10;

            hole_3_group.current.add( floor );

            const hole_loader = new FBXLoader();

            hole_loader.load( 
                gameState.models[ 'hole3' ],
                object => {

                    object.traverse( function ( child ) {

                        console.log( child );

                        if ( child.isMesh ) {

                            if( child.name == 'plants_geo' ){

                                child.material.depthWrite = false;
                            }

                            child.receiveShadow = true;

                            child.castShadow = true;
                        }
                    } );

                    object.scale.set(.095,.095,.095);

                    object.position.y = 12.25;

                    object.position.x = 22;

                    object.position.z = 2;

                    object.rotation.y = toRadians( -208 )

                    hole_3_group.current.add( object );

                    setHoleReady( true );

                    setHolesLoaded( { ...holesLoaded, '3': true } );
                },
                xhr => {

                    updateLoadProgress( (xhr.loaded / xhr.total) * 100 );
                },
                error => {

                    console.log(error)
                }
            );
        }
    }

    const toNextHole = ( hole ) => {

        setHoleReady( false );

        gameDispatch( { type: 'nextHole', hole } );

        idle();
    }

    const loadPlayer = () => {

        const loader = new GLTFLoader();

        const character = gameState.character || 'male';

        loader.load( gameState.models[ character ] , gltf => {

            player.current = gltf.scene;

            player_group.current.add( player.current );

            player.current.scale.set( .06, .06, .06 );

            player.current.position.z = -2.25;

            player.current.position.x = 8;

            if( character == 'female' ){
                
                player.current.position.y = .25;
            }
    
            const skeleton = new SkeletonHelper( player.current );

            skeleton.visible = false;

            player_group.current.add( skeleton );

            player.current.traverse( function ( object ) {

                if ( object.isMesh ){

                    object.castShadow = true;

                    object.frustumCulled = false;
                }

                if( object.name == 'Ch02_Cloth' ){
                    object.material.metalness = 0;
                }
            } );

            mixer.current = new AnimationMixer( player.current );

            mixer.current.addEventListener( 'finished', animationFinished );

            animations.current = gltf.animations;

            setModelReady( true );

            idle();
        } );
    }

    const buildScene = () => {

        clock.current = new Clock();

        scene.current = new Scene();

        scene.current.background = new Color( 0xffffff );

        renderer.current = new WebGLRenderer();

        renderer.current.setSize( gameState.scene_container_el.current.clientWidth, gameState.scene_container_el.current.clientHeight );

        renderer.current.shadowMap.enabled = true;

        renderer.current.shadowMap.type = PCFSoftShadowMap;

        // renderer.current.toneMappingExposure = 1.1;

        let pmremGenerator = new PMREMGenerator( renderer.current );

        pmremGenerator.compileEquirectangularShader();
        
        gameState.scene_container_el.current.appendChild( renderer.current.domElement );

        camera.current = new PerspectiveCamera( 60, gameState.scene_container_el.current.clientWidth / gameState.scene_container_el.current.clientHeight, 0.5, 20000 );

        camera.current.position.x = camera_position.current.x;

        camera.current.position.y = camera_position.current.y;
        
        camera.current.position.z = camera_position.current.z;

        camera.current.lookAt( 0,5,0 );

        const controls = new OrbitControls( camera.current, renderer.current.domElement );
        
        controls.target.set( camera_target.current.x, camera_target.current.y, camera_target.current.z );

        controls.update();

        let dlight = new DirectionalLight( 0xc6c1cd, 1 );

        dlight.position.set(-40, 180, -20);

        dlight.castShadow = true;

        dlight.shadow.camera.top = 40;

        dlight.shadow.camera.bottom = -10;

        dlight.shadow.camera.left = -40;

        dlight.shadow.camera.right = 40;
        
        scene.current.add(dlight);

        var alight = new AmbientLight( 0xc6c1cd, .9 );

        scene.current.add( alight );

        var plight = new PointLight( 0x666666, .5 );

        plight.position.set( -30, 50, 0 )

        scene.current.add( plight );

        slight.current = new SpotLight( 0xc6c1cd, 2.5 );

        const targetObject = new Object3D();

        targetObject.position.set( 0, 0, 300 );

        scene.current.add(targetObject);

        slight.current.target = targetObject;

        slight.current.angle = Math.PI/4;

        slight.current.penumbra = .5;

        slight.current.position.set( -100, 600, 50 );

        scene.current.add( slight.current );

        const ball_geometry = new SphereGeometry( .15, 20, 20 );

        const ball_material = new MeshLambertMaterial( { color: 0xeeeeee } );

        ball.current = new Mesh( ball_geometry, ball_material );

        ball.current.castShadow = true;

        scene.current.add( ball.current );

        hole_group.current = new Group();

        scene.current.add( hole_group.current );

        hole_1_group.current = new Group();

        hole_group.current.add( hole_1_group.current );

        hole_2_group.current = new Group();

        hole_group.current.add( hole_2_group.current );

        hole_3_group.current = new Group();

        hole_group.current.add( hole_3_group.current );

        const marker_geometry = new SphereGeometry( .4, 20, 20  );

        marker_geometry.smooth = false;

        const marker_material = new MeshLambertMaterial( { color: 0x4F00CA, flatShading:true } );

        marker_1.current = new Mesh( marker_geometry, marker_material );

        marker_1.current.position.x = -9;

        marker_1.current.position.y = 1.2;

        marker_1.current.position.z = 2;

        marker_1.current.castShadow = true;

        hole_group.current.add( marker_1.current );

        marker_2.current = new Mesh( marker_geometry, marker_material );

        marker_2.current.position.x = 12;

        marker_2.current.position.y = .2;

        marker_2.current.position.z = 2;

        marker_2.current.castShadow = true;

        hole_group.current.add( marker_2.current );

        player_group.current = new Group();

        scene.current.add( player_group.current );

        loadPlayer();

        toNextHole( 1 );
    }

    const sendMessage = ( message ) => {

        if( socket && socketReady == true ){

            socket.send( JSON.stringify( { message } ) );
        }
    }

    useEffect( () => {

        let angle = orientation <= 180 ? orientation : orientation - 360;

        angle += 90;

        angle += 20;
        
        angle = Math.max( 0, Math.min( 180, angle ) );
        
        toSwingPosition( angle / 180 );

    }, [ orientation ] )

    useEffect( () => {

        if( player.current ){
            
            player.current.position.x = practicing ? 8 : 6;
        }

    }, [ practicing ] )

    useEffect( () => {
        
        gameState_ref.current = gameState;

    }, [ gameState ] );

    useEffect( () => {

        setGameReady( modelReady == true && holeReady == true );
        
    }, [ modelReady, holeReady ] )

    useEffect( () => {

        if( gameReady == true ){

            idle();

            renderScene();
        }

        sendMessage( { type: 'gameReady', ready: gameReady } );

    }, [ gameReady ] )

    useEffect( () => {

        onResize();

    }, [ resizeState.size ] )

    useEffect( () => {

        if( gameState.currentHoleIndex !== null ){

            if( hole_1_group.current ) hole_1_group.current.visible = ( gameState.currentHoleIndex == 0 );

            if( hole_2_group.current ) hole_2_group.current.visible = ( gameState.currentHoleIndex == 1 );

            if( hole_3_group.current ) hole_3_group.current.visible = ( gameState.currentHoleIndex == 2 );

            window.gtag('event', 'hole_change', {
                'event_category' : 'game_play',
                'event_label' : `Hole ${ gameState.currentHoleIndex + 1 }`
            });

            switch( gameState.currentHoleIndex ){

                case 0: buildHole1(); break;

                case 1: buildHole2(); break;

                case 2: buildHole3(); break;
            }
        }

    }, [ gameState.currentHoleIndex ] )

    useEffect( () => {

        sendMessage( { type: 'clubChanged', club:gameState.currentClub } )

        window.gtag('event', 'club_change', {
            'event_category' : 'game_play',
            'event_label' : `Club change ${ gameState.currentClub }`
        });

    }, [ gameState.currentClub ] )

    useEffect( () => {

        sendMessage( { type: 'holeChanged', hole:gameState.currentHole , isLastHole: gameState.currentHoleIndex == gameState.holes.length - 1 } )

    }, [ gameState.currentHole ] )

    useEffect( () => {

            sendMessage( { type: 'updateShotControl', shotControl: gameState.shotControl } );

    }, [ gameState.shotControl ] )

    useEffect( () => {

        if( socketReady == true ){

            socket.addEventListener( 'message', event => {

                let data = JSON.parse( event.data );

                let message = data.message;

                if( message.type == 'powerUp' ) gameDispatch( { type:'setShotControl' , values: { speedFactor: gameState_ref.current.shotControl.speedFactor + 1 } } );

                if( message.type == 'powerDown' ) gameDispatch( { type:'setShotControl' , values: { speedFactor: gameState_ref.current.shotControl.speedFactor - 1 } } );
                
                if( message.type == 'angleUp' ) gameDispatch( { type:'setShotControl' , values: { initHorizontalAngleDegrees: gameState_ref.current.shotControl.initHorizontalAngleDegrees + 1 } } );

                if( message.type == 'angleDown' ) gameDispatch( { type:'setShotControl' , values: { initHorizontalAngleDegrees: gameState_ref.current.shotControl.initHorizontalAngleDegrees - 1 } } );

                if( message.type == 'spinUp' ) gameDispatch( { type:'setShotControl' , values: { spinFactor: gameState_ref.current.shotControl.spinFactor + 1 } } );

                if( message.type == 'spinDown' ) gameDispatch( { type:'setShotControl' , values: { spinFactor: gameState_ref.current.shotControl.spinFactor - 1 } } );

                if( message.type == 'clubUp' ) gameDispatch( { type:'clubUp' } );

                if( message.type == 'clubDown' ) gameDispatch( { type:'clubDown' } );

                if( message.type == 'consoleLog' ) console.log( message.value );

                if( message.type == 'startOver' ) {

                    gameDispatch( { type:'startOver' } );
                }

                if( message.type == 'swing' ) setTimeout( swing, 200 );

                if( message.type == 'setOrientation' ) {
                    
                    if( !gameState_ref.current.currentHole.result && !gameState_ref.current.shotPlaying ){

                        setOrientation( Math.round( message.value ) );
                    }
                }

                if( message.type == 'setPracticing' ){

                    setPracticing( message.value == "True" );

                    practicing_ref.current = message.value == "True";
                }

                if( message.type == 'nextHole' ) toNextHole();

                if( message.type == 'setPower' ){

                    let factor = ( message.value - 500 ) / 100;

                    gameDispatch( { type:'setShotControl' , values: { speedFactor: factor } } );
                }

                if( message.type == 'setSwingPower' ){

                    if( !gameState_ref.current.shotPlaying ){

                        let pow = Number( message.value );

                        if( power_el.current ) power_el.current.style.height = `${ ( 300 * 1.2 ) * pow }px`;

                        gameDispatch( { type:'setShotControl' , values: { speedFactor: ( 10 * pow  ) - ( 20 * ( 1 - pow  ) ) } } );

                        if( !practicing_ref.current ) swing();
                    }
                }
            } )
        }

    }, [ socketReady ] );

    useEffect( () => {

        buildScene();

        window.gtag('event', 'screen_view', {
            'event_category' : 'screens',
            'event_label' : 'Play'
        });

        return () => gameState.scene_container_el.current.removeChild( renderer.current.domElement );
    }, [] )
    
    return (
        <div className="text-center h-100">
            <div className={`scene-controls d-flex align-items-start text-white text-left h-100`} >
                { !gameReady && <div className="cover" style={{position:'fixed', backgroundImage:`url('${ gameState.images.grasstile }')`}}>
                    <div className="text-center" style={{ position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-80%)' }}>
                        <h6 className="text-white">Loading</h6>
                        <h1 className="bold" style={{marginBottom:40}}>{`HOLE ${gameState.currentHoleIndex + 1} `}</h1>
                        <div className="cube">
                            <div className="top" /><div className="right" /><div className="bottom" /><div className="left" /><div className="front" /><div className="back" />
                        </div>
                    </div>
                    <div className="pos-abs w-100 text-center" style={{bottom:0, left:0}}>
                        <h5 className="mb-1 bold">{`${Math.round( loadProgress )}%`}</h5>
                        <div className="w-100 bg-white" style={{height:20}}>
                            <div className="bg-orchid h-100" style={{width:`${loadProgress}%`}}></div>
                        </div>
                    </div>
                </div> }
                { gameReady && <div className="tl-0 p-4 h-100 pos-rel">
                    
                    {/* In shot stats */}
                    { gameState.shotPlaying == true && <div className="pos-abs text-left ml-auto d-flex flex-column">
                        <div className="d-flex p-2 text-violet align-items-center" style={{ backgroundColor:'#ffffffCC', width:280, gap: 40, border:'4px solid #4F00CA', borderRadius:20 }}>
                            <h4 className="medium lh-1">Distance</h4>
                            <h4 className="ml-auto bold">{ `${ gameState.shotStats.distance } yds` }</h4>
                        </div>
                        <div className="mt-1 d-flex p-2 text-violet align-items-center" style={{ backgroundColor:'#ffffffCC', width:280, gap: 40, border:'4px solid #4F00CA', borderRadius:20 }}>
                            <h4 className="medium lh-1">Height</h4>
                            <h4 className="ml-auto bold">{ `${ gameState.shotStats.height } ft` }</h4>
                        </div>
                    </div> }

                    { !gameState.currentHole.result && <div className="ml-6 pos-abs cover d-flex flex-column overflow-hidden align-items-center" style={{ justifyContent:'space-evenly', width:30, height:300, backgroundColor:'#00000066', border:'2px solid white', borderRadius:20, top:'68%', transform:'translateY(-50%)'}} >
                        <div ref={ power_el } className="pos-abs align-items-center justify-content-center overflow-hidden z-0" style={{ transition:'height .3s', bottom:0, minHeight:10, width:'100%', height:0, backgroundColor:'white' }}>
                            <div className="pos-abs" style={{ bottom:0, width:'100%', height:300, background: 'linear-gradient(0deg, rgba(161,217,19,1) 0%, rgba(47,198,30,1) 100%)' }}></div>
                        </div>
                        <div className="pos-rel z-1" style={{backgroundColor:'white', width:8, height:8, borderRadius:4}}></div>
                        <div className="pos-rel z-1" style={{backgroundColor:'white', width:8, height:8, borderRadius:4}}></div>
                        <div className="pos-rel z-1" style={{backgroundColor:'white', width:8, height:8, borderRadius:4}}></div>
                        <div className="pos-rel z-1" style={{backgroundColor:'white', width:8, height:8, borderRadius:4}}></div>
                    </div> }
                </div> }
                {/* Shot summary */}
                { ( gameState.currentHole && gameState.currentHole.result && gameState.shotPlaying == false ) && <div className="d-flex p-4 text-dark-gray pos-abs w-100 justify-content-center" style={{ gap:10 }}>
                     {/* Good or bad shot */}
                    <div className="p-4 text-center" style={{borderRadius:20, backgroundColor:'#ffffffdd', border:'9px solid #4F00CA' }} >
                        <div>
                            <h4 style={{marginBottom:8}} className="bold text-dark-gray">{ gameState.currentHole.result.message }</h4>
                            <h3 className="bold text-violet">{ `${gameState.currentHole.result.score} points` }</h3>
                            {/* <h5 className="bold text-dark-gray mt-1">{ `${gameState.currentHole.result.shot_distance }` }</h5> */}
                        </div>
                    </div>
                </div> }
                { ( gameReady && gameState.currentHoleIndex !== null ) && <div className="p-4 text-left ml-auto d-flex flex-column" style={{ alignItems:'flex-end' }}>
                    {/* testing */}
                    {/* <div className="p-2 d-flex pos-fixed" style={{ gap:2, top:0, left:300, zIndex:100}}>
                        <button className="p-2 bg-white text-violet bold" onClick={()=>swing()}>Swing</button>
                        <button className="p-2 bg-white text-violet bold" onClick={()=>toNextHole()}>Next Hole</button>
                    </div> */}
                    {/* score */}
                    <div className="d-flex p-2 text-violet align-items-center" style={{ backgroundColor:'#ffffffCC', width:280, gap: 40, border:'4px solid #4F00CA', borderRadius:20 }}>
                        <h4 className="medium lh-1">Points</h4>
                        <h3 className="ml-auto bold">{ `${ gameState.totalScore }` }</h3>
                    </div>
                    {/* Hole */}
                    <div className="mt-1 d-flex p-2 text-white align-items-center" style={{ width:280, gap: 40, backgroundColor:'#00000099', border:'2px solid white', borderRadius:20 }}>
                        <h4 className="medium lh-1">Hole</h4>
                        <h4 className="ml-auto bold lh-1">{ gameState.currentHoleIndex + 1 } of 3</h4>
                    </div>
                    <div className="mt-1 d-flex p-2 text-white align-items-center" style={{ width:280, gap: 40, backgroundColor:'#00000099', border:'2px solid white', borderRadius:20 }}>
                        {/* <h4 className="medium lh-1">Hole { gameState.currentHoleIndex + 1 }</h4> */}
                        <h4 className="medium lh-1">Distance</h4>
                        <h4 className="ml-auto bold lh-1">{ `${ gameState.currentHole.center.z } yds` }</h4>
                    </div>
                    {/* Hole stats*/}
                    <div className="mt-1 d-flex p-2 text-white align-items-center" style={{ width:280, gap: 20, backgroundColor:'#00000066', border:'2px solid white', borderRadius:20 }}>
                        <h4 className="medium lh-1">Wind</h4>
                        <h4 className="ml-auto bold lh-1">{`${gameState.wind.speed} mph`}</h4>
                        <WindIcon style={{ width:20, transformOrigin:'center center', transform:`rotate( ${gameState.wind.direction-45}deg )` }} />
                    </div>
                    {/* Club selection */}
                    { gameState.currentClub && 
                    <div className="mt-1 d-flex p-2 text-white align-items-center" style={{ marginBottom:-2, width:280, gap: 40, backgroundColor:'#00000066', border:'2px solid white', borderRadius:'20px 20px 0 0' }}>
                        <h4 className="medium lh-1">Club</h4>
                        <h4 className="ml-auto bold lh-1">{ gameState.currentClub.name }</h4>
                    </div> }
                    {/* AIM */}
                    <div className="p-2 align-items-center" style={{ width:280, gap: 40, backgroundColor:'#00000066', border:'2px solid white', borderRadius:'0 0 20px 20px' }}>
                        <div className="ml-auto text-center">
                            <div style={{marginBottom:-48}}>
                                <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="46.9438" cy="46.9438" r="46.9438" fill="#A0A0D9"/>
                                    <circle cx="46.3337" cy="46.8826" r="34.6325" fill="#4F00CA"/>
                                    <circle cx="46.7183" cy="47.4977" r="24.2427" fill="#C053EF"/>
                                    <g clipPath="url(#clip0_317_164)">
                                        <path d="M44.7944 44.0345C44.7944 44.0345 45.564 43.2649 47.8729 43.2649C50.1817 43.2649 51.7209 44.8041 54.0298 44.8041C56.3386 44.8041 57.1082 44.0345 57.1082 44.0345V34.7992C57.1082 34.7992 56.3386 35.5688 54.0298 35.5688C51.7209 35.5688 50.1817 34.0296 47.8729 34.0296C45.564 34.0296 44.7944 34.7992 44.7944 34.7992V44.0345Z" fill="#902BF8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M44.7944 49.4218V44.0345" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_317_164">
                                            <rect width="18.4707" height="18.4707" fill="white" transform="translate(41.7158 32.4903)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className="d-flex justify-content-center" style={{height:220, overflow:'visible', alignItems:'flex-end'}}>
                                <div className="d-flex flex-column align-items-center" style={{ borderRadius:7, backgroundColor:'#ffffff44', transformOrigin:"center bottom", transform:`translateX(-2px) rotateZ(${ -gameState.shotControl.initHorizontalAngleDegrees }deg)`, width:14, height:280 * ( gameState.currentClub.distance / gameState.currentHole.center.z ) , justifyContent:'space-between' }}>
                                    <div style={{width:6, height:'14%'}}></div>
                                    <div style={{backgroundColor:'white', width:8, height:8, borderRadius:4}}></div>
                                    <div style={{backgroundColor:'white', width:8, height:8, borderRadius:4}}></div>
                                    <div style={{backgroundColor:'white', width:8, height:8, borderRadius:4}}></div>
                                    <div style={{backgroundColor:'white', width:8, height:8, borderRadius:4}}></div>
                                    <div style={{width:6, height:'40%'}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default DGamePlayScreen
